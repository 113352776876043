import { getFirstDeviceStateDataValueFromStudyStates } from './helper'

export const getFirstUserCalValueFromStudyStates = (states, key) => {
  const userCal = getFirstDeviceStateDataValueFromStudyStates(states, 'user_cal')
  if (userCal?.length) {
    for (const userCalItem of userCal) {
      if (userCalItem?.Key ===  key) {
        for (const userCalItemValue of userCalItem.Value) {
          if (userCalItemValue.Key === 'value') {
            return userCalItemValue.Value
          }
        }
      }
    }
  }

  return null
}

export const getCalibrationDateFromStudyStates = (states) => {
  return getFirstDeviceStateDataValueFromStudyStates(states, 'calibration_date')
}

export const getFlowCalibrationFactorFromStudyStates = (states) => {
  return getFirstDeviceStateDataValueFromStudyStates(states, 'flow_calibration_factor')
}

export const getPhotometricCalFactorFromStudyStates = (states) => {
  return getFirstUserCalValueFromStudyStates(states, 'photometric_cal_factor')
}

export const getSizeCalFactorFromStudyStates = (states) => {
  return getFirstUserCalValueFromStudyStates(states, 'size_cal_factor')
}
