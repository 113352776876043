<template>
  <pop-up-message-dialog
    :open="open"
    :title="$t('movedToAnotherDeviceDialog.title')"
    :messages="messages"
    @close="close"
  />
</template>

<script>
import PopUpMessageDialog from './PopUpMessageDialog.vue'
import {
  AddUserSetting,
  filterMovedToAnotherDeviceMessages,
} from '../../services/user-settings'
import { featureFlags } from '../../services/feature-flags'

export default {
  name: 'MovedToAnotherDeviceDialog',
  components: { 'pop-up-message-dialog': PopUpMessageDialog },
  data() {
    return {
      open: false,
      movedToAnotherDeviceMessage: null,
    }
  },
  mounted() {
    this.setMovedToAnotherDeviceMessage()
  },
  watch: {
    movedToAnotherDeviceMessages() {
      this.setMovedToAnotherDeviceMessage()
    },
  },
  computed: {
    enableMessage() {
      return this.$store.state.featureFlags?.appFlags
        ? this.$store.getters['featureFlags/getFeatureFlagBySlug'](
            featureFlags.EnabledSharingForSBAndConnectedDevices
          )
        : false
    },
    messages() {
      const movedToAnotherDeviceMessages = []
      if (this.movedToAnotherDeviceMessage) {
        movedToAnotherDeviceMessages[0] = this.$t(
          'movedToAnotherDeviceDialog.messagePartOne',
          {
            connectedDeviceSerial:
              this.movedToAnotherDeviceMessage.connectedDeviceSerial,
            smartBridgeSerial:
              this.movedToAnotherDeviceMessage.smartBridgeSerial,
          }
        )
        movedToAnotherDeviceMessages[1] = this.$t('movedToAnotherDeviceDialog.messagePartTwo')
      }
      return movedToAnotherDeviceMessages
    },
    movedToAnotherDeviceMessages() {
      return this.$store.getters['user/getMovedToAnotherDeviceMessages']
    },
  },
  methods: {
    close() {
      this.removeMovedToAnotherDeviceMessage(this.movedToAnotherDeviceMessage)
      this.movedToAnotherDeviceMessage = null
      this.open = false
    },
    removeMovedToAnotherDeviceMessage(movedToAnotherDeviceMessage) {
      const movedToAnotherDeviceMessages = filterMovedToAnotherDeviceMessages(
        this.movedToAnotherDeviceMessages,
        movedToAnotherDeviceMessage
      )
      const setting = AddUserSetting({ movedToAnotherDeviceMessages })
      //update store
      this.$store.dispatch('user/updateSettings', setting)

      try {
        //update api
        this.$api.patchUserSettings(setting)
      } catch (err) {
        // close modal
      }
    },
    setMovedToAnotherDeviceMessage() {
      if (
        this.enableMessage &&
        this.movedToAnotherDeviceMessages?.length &&
        !this.movedToAnotherDeviceMessage
      ) {
        this.movedToAnotherDeviceMessage = this.movedToAnotherDeviceMessages[0]
        this.open = true
      }
    },
  },
}
</script>
