import { UnitOfMeasure, getInstance as getUnitsInstance } from '@fusion/units'
import store from '@/store'
import {
  measurementTypes,
  sensorTypesKey,
  SENSOR_TYPES,
  getMeasurementTypeKeyByName,
} from '@/services/device-measurements'
import { Measurement_Names } from '@/store/devices/utils.js'
import {
  getPMSelectedUnit,
  getTemperatureSelectedUnit,
  getPressureSelectedUnit,
} from '@/services/user-settings.js'

const PRESSURE_NAME = 'baro_inhg'
const TEMPERATURE_NAME = 'temp_c'

export const SUPPORTED_UNITS = {
  PM: [
    UnitOfMeasure.MICROGRAMS_PER_CUBIC_METER,
    UnitOfMeasure.MILLIGRAMS_PER_CUBIC_METER,
  ],
  PRESSURE: [
    UnitOfMeasure.INCH_MERCURY,
    UnitOfMeasure.HECTOPASCAL,
    UnitOfMeasure.MILLIBAR,
  ],
  TEMPERATURE: [UnitOfMeasure.DEGREES_C, UnitOfMeasure.DEGREES_F],
}

export const tempUnits = {
  celsius: 0,
  fahrenheit: 1,
  kelvin: 2,
}

export const convertReading = (
  reading,
  measurementName,
  selectedUnit,
  deviceType = '',
  supportedUnits = []
) => {
  let convertedValue
  if (reading && reading.value !== undefined) {
    // If the measurement has supported units, do the corresponding validation
    const isUnitSupported = supportedUnits?.length
      ? supportedUnits.includes(selectedUnit)
      : true
    if (isUnitSupported) {
      const unitsLibrary = getUnitsInstance()
      const metrology = unitsLibrary.getMetrology(deviceType, measurementName)
      if (metrology) {
        return unitsLibrary.convertUnitByMetrology(
          metrology.unit,
          selectedUnit || metrology.unit,
          reading.value,
          metrology
        )
      }
    }
  }

  return convertedValue
}

export const getConvertedReading = (
  readings,
  measurementName,
  selectedUnit,
  deviceType,
  isAllowedAndSupported,
  supportedUnits
) => {
  if (readings && isAllowedAndSupported) {
    const baseReading =
      readings[measurementName] || readings[Measurement_Names[measurementName]]
    if (baseReading) {
      const newValue = convertReading(
        baseReading,
        measurementName,
        selectedUnit,
        deviceType,
        supportedUnits
      )
      if (newValue !== undefined) {
        return {
          ...baseReading,
          value: newValue,
        }
      }
    }
  }
  return null
}

export const getReadingPM = (readings, measurementName, pmUnit, deviceType) => {
  return getConvertedReading(
    readings,
    measurementName,
    pmUnit,
    deviceType,
    true,
    SUPPORTED_UNITS.PM
  )
}

export const getReadingTemperature = (readings, tempUnit, deviceType) => {
  return getConvertedReading(
    readings,
    TEMPERATURE_NAME,
    tempUnit,
    deviceType,
    true,
    SUPPORTED_UNITS.TEMPERATURE
  )
}

export const getReadingPressure = (
  readings,
  pressureUnit,
  deviceType,
  isAllowedAndSupported = true
) => {
  return getConvertedReading(
    readings,
    PRESSURE_NAME,
    pressureUnit,
    deviceType,
    isAllowedAndSupported,
    SUPPORTED_UNITS.PRESSURE
  )
}

export function getReadingValue(
  readings,
  measurementName,
  deviceType,
  isAllowedAndSupported = true
) {
  const measurementTypeKey = getMeasurementTypeKeyByName(measurementName)
  const sensorType = sensorTypesKey[measurementTypeKey]

  // PM conversion
  if (sensorType === SENSOR_TYPES.PM) {
    return getReadingPM(
      readings,
      measurementName,
      getPMSelectedUnit(store),
      deviceType
    )
  }

  switch (measurementTypeKey) {
    // Temperature conversion
    case measurementTypes.temp:
      return getReadingTemperature(
        readings,
        getTemperatureSelectedUnit(store),
        deviceType
      )
    // Pressure conversion
    case measurementTypes.baro_inhg:
      return getReadingPressure(
        readings,
        getPressureSelectedUnit(store),
        deviceType,
        isAllowedAndSupported
      )
    // Measurements that don't require conversion
    default:
      return getConvertedReading(
        readings,
        measurementName,
        null,
        deviceType,
        isAllowedAndSupported,
        null
      )
  }
}
