var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "study-footer" },
        _vm._l(_vm.calibrationFields, function (field) {
          return _c(field.componentName, {
            key: field.dataCy,
            tag: "component",
            attrs: {
              value: field.value,
              label: field.label,
              invalidText: field.invalidText,
              customClass: "small-subtitle",
              dataCy: field.dataCy,
            },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }