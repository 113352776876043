<template>
  <popover-text
    :value="formattedValue"
    :label="label"
    :customClass="customClass"
    :dataCy="dataCy"
  />
</template>

<script>
import popoverText from '@/components/devicePopover/content/popoverText.vue'

export default {
  name: 'popoverNumber',
  components: {
    'popover-text': popoverText,
  },
  props: {
    value: Number,
    label: String,
    invalidText: String,
    customClass: String,
    dataCy: String,
  },
  computed: {
    formattedValue() {
      const noTS = this.invalidText || this.$t('map.popover.valueBlank')
      return typeof this.value === 'number' ? `${this.value}` : noTS
    },
  },
}
</script>
