<template>
  <v-app>
    <fusion-appbar
      :logoSrc="logoSrc"
      :isLoading="$auth.isLoading"
      :hasDrawer="isAuthenticated"
      @drawerClicked="drawerClick()"
      @navigateHome="navigateHome()"
    >
      <span
        :class="{
          'application-burger':
            isAuthenticated && $vuetify.breakpoint.lgAndDown,
        }"
        slot="burger"
      >
        <v-app-bar-nav-icon
          v-if="isAuthenticated && $vuetify.breakpoint.lgAndDown"
          @click.stop="drawerClick()"
          class="mx-0 text"
          x-large
          :color="$vuetify.theme.currentTheme.textPrimary"
          data-cy="hamburgerButton"
        />
      </span>
      <template slot="right-toolbar">
        <global-notifications v-if="isAuthenticated" />
        <fusion-authenticator
          :menuItems="accountMenuItems"
          :userProfileTabItems="userProfileTabItems"
          @navigateHome="navigateHome()"
        />
      </template>
    </fusion-appbar>
    <v-navigation-drawer
      v-if="$auth.isAuthenticated"
      v-model="drawer"
      :clipped="drawerClipped"
      :permanent="drawerPermanent"
      :mini-variant="drawerMini"
      color="#f2f2f2"
      mini-variant-width="68"
      width="240"
      height="100%"
      app
    >
      <fusion-sidebar :routes="router" data-cy="appSideBar"></fusion-sidebar>
      <template v-if="drawer" v-slot:append>
        <v-list-item @click.stop="openFeedback" data-cy="feedbackIcon">
          <v-list-item-action>
            <v-icon>mdi-bullhorn</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('app.feedback.feedback') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <span class="footer" v-if="!drawerMini">
          <span class="footer--logo">
            &copy; {{ new Date().getFullYear() }}
            {{ $t('app.copyright') }}
          </span>
          <span class="footer--version"
            >v{{ $store.state.releaseVersion }}</span
          >
        </span>
      </template>
    </v-navigation-drawer>
    <fusion-feedback
      :open="dialogs.feedback"
      :helpRoute="'/help'"
      @close="dialogs.feedback = false"
    />
    <v-main class="background-image">
      <v-container class="ma-0 pa-0" fluid>
        <router-view />
        <fusion-banner-message />
        <moved-to-another-device-dialog />
      </v-container>
    </v-main>
    <fusion-snackbar></fusion-snackbar>
    <fusion-feature-flags :user="authUserData" />
  </v-app>
</template>

<script>
import globalNotifications from '@/components/alerts/GlobalNotifications.vue'
import MovedToAnotherDeviceDialog from '@/components/dialogs/MovedToAnotherDeviceDialog.vue'
import { getModelsMetaData } from '@/services/device-service'
import { DEVICE_UPDATE_INTERVAL_MS } from '@/services/device-metadata'
import { checkStatus } from '@/api/services/utils'
import { mapActions } from 'vuex'

const TSI_HOME_LINK = 'https://tsi.com/home/'

export default {
  components: {
    'global-notifications': globalNotifications,
    'moved-to-another-device-dialog': MovedToAnotherDeviceDialog,
  },
  created() {
    document.title = this.$t('app.documentTitle')
  },
  async mounted() {
    if (this.isAuthenticated) {
      this.startDataUpdateInterval()
      await this.updatePermissions()
    }
  },
  data() {
    return {
      accountMenuItems: [
        {
          id: 'spacer',
        },
      ],
      userProfileTabItems: [
        {
          title: this.$t('userProfile.unitSettings.title'),
          componentName: 'fusion-unit-settings'
        },
        {
          title: this.$t('accountSubscriptions.title'),
          componentName: 'account-subscriptions',
        },
      ],
      dialogs: {
        feedback: false,
      },
      noteNum: 7,
      drawer: false,
      mini: true,
      logoSrc: '/img/tsi_link_logo.svg',
      progress: {
        opacity: 0.3,
        bufferVal: 100,
        height: 4,
      },
      devicesInterval: null,
    }
  },
  watch: {
    async isAuthenticated() {
      if (this.isAuthenticated) {
        this.startDataUpdateInterval()
        await this.updatePermissions()
      } else {
        this.stopDevicesInterval()
      }
    },
    inMapMovingMode(isMoving) {
      if (isMoving) {
        this.stopDevicesInterval()
      } else {
        this.startDataUpdateInterval()
      }
    },
    currentRouteName(currentRouteName) {
      if (currentRouteName && this.isAuthenticated) {
        // Refresh movedToAnotherDeviceMessage every time the user goes to a different view.
        this.setUserSettings()
      }
    }
  },
  computed: {
    router() {
      return this.$router?.options?.routes
    },
    isAuthenticated() {
      return this.$auth.isAuthenticated
    },
    drawerPermanent() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    drawerClipped() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    drawerMini() {
      return (
        (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.md) &&
        this.mini
      )
    },
    isLoading() {
      return this.$auth.loading
    },
    authUserData() {
      let user = {}
      if (
        this.$auth?.user &&
        this.$auth.user['https://tsi.com/user_metadata']?.account_id
      ) {
        user = this.$auth.user
      }
      return user
    },
    inMapMovingMode() {
      return this.$store.state.map.movingMode
    },
    isLoggedInAs() {
      return this.$store.getters['loginas/getIsLogInAs']
    },
    currentRouteName() {
      return this.$route?.name || ''
    }
  },
  methods: {
    ...mapActions('loginas', ['updateImpersonatedUserPermissions']),
    drawerClick() {
      if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg) {
        this.mini = !this.mini
      } else {
        this.drawer = !this.drawer
      }
    },
    openFeedback() {
      this.dialogs.feedback = true
    },
    navigateHome() {
      window.open(TSI_HOME_LINK)
    },
    updateSubscriptions(devices) {
      this.$store.dispatch('subscriptions/updateSubscriptions', {
        auth: this.$auth,
        api: this.$api,
        devices: devices || [],
      })
    },
    startDataUpdateInterval() {
      this.getModelsMeta()
      this.getUserDevices()
      // Updates data (devices, subscriptions, telemetry, and user settings) in Vuex every 60 seconds.
      this.devicesInterval = setInterval(
        this.updateUserData,
        DEVICE_UPDATE_INTERVAL_MS
      )
    },
    stopDevicesInterval() {
      clearInterval(this.devicesInterval)
      this.devicesInterval = null
      this.updateSubscriptions()
    },
    async getUserDevices() {
      await this.$store.dispatch('devices/setAllDevices')
      const devices = this.$store.state.devices.allDevices
      this.updateSubscriptions(devices)
    },
    async getModelsMeta() {
      const modelsData = await getModelsMetaData()
      this.$store.dispatch('devicemodels/setModelsMetaData', modelsData)
    },
    async setUserSettings() {
      try {
        const resp = await this.$api.getUserSettings()
        if (checkStatus(resp)) {
          const body = await resp.json()
          await this.$store.dispatch('user/setSettings', body)
        } else {
          await this.$store.dispatch('user/setSettings', {
            bluesky: { visited: false },
          })
        }
      } catch (error) {
        // do not show user settings
      }
    },
    async updatePermissions() {
      if(this.isLoggedInAs) {
        await this.updateImpersonatedUserPermissions({api: this.$api, auth: this.$auth})
      }
    },
    updateUserData() {
      this.getUserDevices()
      this.setUserSettings()
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/global-variables.scss';

main {
  background-image: $background-gradient;
  background-repeat: repeat;
}

.application-burger {
  background-color: #fff;
  height: 100%;
  flex: inherit;
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.restrictedContent {
  cursor: not-allowed;
  opacity: 0.36;
}

.footer {
  opacity: 0.3;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.v-badge__badge {
  height: unset !important;
  min-width: unset !important;
}

.v-alert {
  position: absolute;
  z-index: 100;
  top: 0;
  margin: 24px;
}

.authenticator--wrapper .v-btn[data-cy='loginButton'] {
  display: none;
}

.not-available-text {
  color: rgba(0, 0, 0, 0.32);
  margin-left: 8px;
}
</style>
