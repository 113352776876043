var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pop-up-message-dialog", {
    attrs: {
      open: _vm.open,
      title: _vm.$t("movedToAnotherDeviceDialog.title"),
      messages: _vm.messages,
    },
    on: { close: _vm.close },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }