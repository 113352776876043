<template>
  <v-card-subtitle :class="customClass" :data-cy="dataCy">
    <span> {{ label }}: </span>
    <span class="value">
      {{ formattedText }}
    </span>
  </v-card-subtitle>
</template>

<script>
export default {
  props: {
    value: String,
    label: String,
    invalidText: String,
    customClass: String,
    dataCy: String,
  },
  computed: {
    formattedText() {
      const noTS =
        this.invalidText || this.$t('map.popover.notApplicable')
      return this.value || noTS
    },
  },
}
</script>
