<template>
  <v-card>
    <v-card-title class="dark-background">
      <v-btn
        text
        color="white"
        data-cy="cancelDateSelectionButton"
        @click="cancel"
      >
        {{ $t('buttons.cancel')}}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="white"
        data-cy="saveDateSelectionButton"
        @click="applyCustomDateRange"
      >
        {{ $t('buttons.save') }}
      </v-btn>
    </v-card-title>
    <div class="range-container">
      <div class="range-label">{{ $t('map.historyDialog.datePicker.rangeLabel') }}</div>
      <div class="range-value">
        <span :class="{ 'default-value': !customDateRange.start }"  data-cy="customStartDate">
          {{ formatDate(customDateRange.start, $t('map.historyDialog.datePicker.defaultRangeStart')) }}
        </span>
        <span :class="{ 'default-value': !customDateRange.start }"> - </span>
        <span :class="{ 'default-value': !customDateRange.end }" data-cy="customEndDate">
          {{ formatDate(customDateRange.end, $t('map.historyDialog.datePicker.defaultRangeEnd')) }}
        </span>
        <v-icon
          class="clear-icon"
          @click="clearDates"
          v-if="customDateRangeModel.length"
          small
          data-cy="clearDateSelectionButton"
        >
          mdi-close-circle-outline
        </v-icon>
      </div>
    </div>
    <v-date-picker
      v-model="customDateRangeModel"
      full-width
      no-title
      range
      color="primary"
      :min="minCustomDate"
      :max="maxCustomDate"
      data-cy="datePickerSelector"
    />
  </v-card>
</template>

<script>
  const DATE_TODAY = new Date()
  const ONE_DAY_MS = 1000 * 60 * 60 * 24
  // using 94 days because we also include the partial day of data from the current date
  // so this imposes a real total limit of 95 full days in accordance with the devices API
  const NINETY_FOUR_DAYS_MS = ONE_DAY_MS * 94
  const ABSOLUTE_DAYS_AGO_LIMIT = 397
  const ABSOLUTE_MIN_DATE = new Date(DATE_TODAY.getTime() - ABSOLUTE_DAYS_AGO_LIMIT * ONE_DAY_MS)

  export default {
    name: 'datePicker',
    data() {
      return {
        customDateRangeModel: [],
      }
    },
    computed: {
      customDateRange() {
        let range = {
          start: null,
          end: null
        }
        // convert ISO8091 date strings back to dates
        const customDates = this.customDateRangeModel.map((d) => new Date(d.split('-')))
        switch (customDates.length) {
          case 0: break
          case 1:
            range.start = new Date(customDates[0])
            break
          case 2: {
            // sort oldest to newest rather than by order of selection
            customDates.sort((a,b) => a - b)
            // extend end date timestamp to the end of that day or the current time, whichever is earlier
            const endDateEOD = new Date(customDates[1].getTime() + ONE_DAY_MS - 1)
            customDates[1] = new Date(Math.min(...[DATE_TODAY, endDateEOD])) // spread operator implicitly converts dates to numbers
            range = {
              start: customDates[0],
              end: customDates[1]
            }
            break
          }
        }
        return range
      },
      maxCustomDate() {
        let maxDate = new Date()
        if (this.customDateRangeModel.length === 1) {
          const selectedDate = new Date(this.customDateRangeModel[0])
          const ninetyFourDaysFuture = new Date(selectedDate.getTime() + NINETY_FOUR_DAYS_MS)
          // if 94 days past the selected date would be in the future, make max date current date
          maxDate = new Date(Math.min(...[maxDate, ninetyFourDaysFuture])) // spread operator implicitly converts dates to numbers
        }
        return this.formatDateISO8601(maxDate)
      },
      minCustomDate() {
        let minDate = ABSOLUTE_MIN_DATE
        if (this.customDateRangeModel.length === 1) {
          const selectedDate = new Date(this.customDateRangeModel[0])
          const ninetyFourDaysPast = new Date(selectedDate.getTime() - NINETY_FOUR_DAYS_MS)
          // if 94 days before the selected date would be earlier than the absolute min, make min date absolute min
          minDate = new Date(Math.max(...[minDate, ninetyFourDaysPast])) // spread operator implicitly converts dates to numbers
        }
        return this.formatDateISO8601(minDate)
      }
    },
    methods: {
      applyCustomDateRange(){
        if (this.customDateRangeModel.length !== 2) {
          return this.cancel()
        }
        this.$emit('done', this.customDateRange)
      },
      cancel() {
        this.$emit('done', undefined)
      },
      clearDates() {
        this.customDateRangeModel = []
      },
      // convert a Date object to mmm DD YYYY format or return defaultValue
      formatDate(date, defaultValue) {
        const formatter = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        })
        return date
          ? formatter.format(date)
          : defaultValue
      },
      formatDateISO8601(date) {
        // Convert a Date object to YYYY-MM-DD format
        return date ? date.toISOString().split("T")[0] : null
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/global-variables.scss';

  .dark-background {
    background-color: black
  }

  .range-container {
    background-color: $white-smoke-color; /* Light gray background */
    padding: 8px 16px;
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .range-label {
    font-size: 12px;
    text-transform: uppercase;
    color: black;
  }

  .range-value {
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 4px;
    display: flex;
    align-items: center; /* Center content vertically */
    gap: 8px; /* Space between text and the clear icon */
  }

  .range-value .default-value {
    color: #9e9e9e; /* Gray text for default values */
  }

  .range-value span:not(.default-value) {
    color: black; /* Black text when values are present */
  }
</style>
