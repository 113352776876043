var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "study-container": _vm.showStudyContent } },
    [
      !_vm.isLoading && !!_vm.lastTelemetryTimestamp
        ? _c(
            "div",
            [
              _vm.showStudyContent
                ? _c(
                    "div",
                    { staticClass: "study-details" },
                    [
                      _c("popover-timestamp", {
                        attrs: {
                          value: _vm.minTimestamp,
                          label: _vm.$t("widget.currentReadings.studyStart"),
                          customClass: "small-subtitle",
                          dataCy: "studyStart",
                        },
                      }),
                      _c("popover-timestamp", {
                        attrs: {
                          value: _vm.maxTimestamp,
                          label: _vm.$t("widget.currentReadings.studyStop"),
                          customClass: "small-subtitle",
                          dataCy: "studyStop",
                        },
                      }),
                    ],
                    1
                  )
                : _c("popover-last-telemetry-timestamp", {
                    attrs: { deviceId: _vm.deviceId },
                  }),
              _c("one-measurement-monitoring", {
                attrs: {
                  deviceId: _vm.deviceId,
                  selectedParameter: _vm.selectedParameter,
                  measurementReadings: _vm.readings,
                  size: _vm.showStudyContent ? "medium" : "large",
                },
              }),
              _vm.showStudyContent
                ? _c("measurement-statistics", {
                    attrs: {
                      deviceId: _vm.deviceId,
                      measurementStatisticsReadings:
                        _vm.measurementStatisticsReadings,
                      selectedParameter: _vm.selectedParameter,
                    },
                  })
                : _vm._e(),
              _c("device-conditions", {
                attrs: {
                  deviceConditionsArray: _vm.deviceConditionsErrors,
                  latestTelemetryTimestamp: _vm.latestStudyTelemetryTimestamp,
                },
              }),
            ],
            1
          )
        : !_vm.isLoading
        ? _c("no-data-container")
        : _c("loading-container"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }