export default {
  getDevicesUpdated(state) {
    return state.settings.devicesUpdated
  },
  getViewedSubscriptionExpired(state) {
    return state.settings.viewedSubscriptionExpired
  },
  getLastViewedVersion(state) {
    return state.settings.lastViewedVersion
  },
  getVisited(state) {
    return state.settings.visited
  },
  getMovedToAnotherDeviceMessages(state) {
    return state.settings.movedToAnotherDeviceMessages
  },
}
