var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
        customCss: "pop-up-message-dialog",
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "v-card-title",
                { attrs: { "data-cy": "popUpMessageHeader" } },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary white--text",
                  attrs: { text: "", "data-cy": "popUpMessageDone" },
                  on: { click: _vm.close },
                },
                [_vm._v(_vm._s(_vm.$t("buttons.done")))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("v-card-text", [
        _vm.messages && _vm.messages.length
          ? _c(
              "div",
              { attrs: { "data-cy": "popUpMessageContent" } },
              _vm._l(_vm.messages, function (message, index) {
                return _c("p", { key: index }, [_vm._v(_vm._s(message))])
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }