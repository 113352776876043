import { measurementTypes } from '@/store/map/utils'
import { UnitOfMeasureProperties } from '@fusion/units'

export function getReadings(sensorReadings, noVal, noTS) {
  const r = {}
  pullValues(r, sensorReadings, noVal, noTS)
  return r
}

function pullValues(r, readings, noVal, noTS) {
  if (readings?.length) {
    readings.forEach((measurement) => {
      r[measurement.name] =
        measurement.value || measurement.value === 0 ? measurement.value : noVal
    })
    r.timestamp = readings[0].timestamp || noTS
  }
}

export function getMeasurementByType(measurements, type, valueIfNotFound) {
  // why is type always sps30_mcpm2x5_aqi this is never what is in the measurements SAD
  if (measurements?.length > 0) {
    const measurement = measurements.filter((m) => m.name === type)[0]
    //can we assume one with each type? type doesn't even seem to be the right thing to compage to, most don't match
    return measurement ? measurement.value : valueIfNotFound
  }
  return valueIfNotFound
}

export function getMeasurementTypes(measType) {
  let pmType = ''
  switch (measType) {
    case measurementTypes.pm25:
      pmType = 'mcpm2x5'
      break
    case measurementTypes.pm10:
      pmType = 'mcpm10'
      break
  }
  return pmType
}

export const getReadingUnitLabel = (unit) => {
  return UnitOfMeasureProperties[unit]?.code ? UnitOfMeasureProperties[unit].code : ''
}
