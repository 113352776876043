var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-subtitle",
    { class: _vm.customClass, attrs: { "data-cy": _vm.dataCy } },
    [
      _c("span", [_vm._v(" " + _vm._s(_vm.label) + ": ")]),
      _c("span", { staticClass: "value" }, [
        _vm._v(" " + _vm._s(_vm.formattedText) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }