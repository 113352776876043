<template>
  <popover-text
    :value="formattedTimestamp"
    :label="label"
    :customClass="customClass"
    :dataCy="dataCy"
  />
</template>

<script>
import popoverText from '@/components/devicePopover/content/popoverText.vue'
import { validateTimestamp } from '@/services/device-status'

export default {
  name: 'popoverTimestamp',
  components: {
    'popover-text': popoverText,
  },
  props: {
    value: String,
    label: String,
    invalidText: String,
    customClass: String,
    dataCy: String,
  },
  computed: {
    formattedTimestamp() {
      const noTS =
        this.invalidText ||
        this.$t('map.popover.timestamp.lastMeasurementNever')
      const timestamp = validateTimestamp(this.value, noTS)

      return timestamp != noTS
        ? new Date(timestamp).toLocaleString()
        : timestamp
    },
  },
}
</script>
