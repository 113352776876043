import { LOG_IN_AS_HEADER_NAMES } from './utils'
import { getLogInAsHeaders } from './helper'

export const getLatestStudyRegistrations = async function () {
  return this.GET(
    '/studies/registration/serials/latest?ignoredGatewayModels=sbd1,SBD1,SmartBridge',
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
      ]),
    },
    {},
    process.env.VUE_APP_STUDIES_HOST_V2
  )
}

export const buildQueryString = (includeChildren, includeOnlyLatestTelemetry) => {
  const queryParams = []

  if (includeChildren) {
    queryParams.push('study_type=timeseries', 'study_type=stel')
  } else {
    queryParams.push('study_type=timeseries')
  }

  if (includeOnlyLatestTelemetry) {
    queryParams.push('latest_telemetry=true')
  }

  return queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
}

export const getLatestDeviceStudy = async function (devId, includeChildren, includeOnlyLatestTelemetry) {
  const queryString = buildQueryString(includeChildren, includeOnlyLatestTelemetry)
  const endpoint = `/studies/devices/${devId}/latest${queryString}`
  return this.GET(
    endpoint,
    {},
    {},
    process.env.VUE_APP_STUDIES_HOST_V3
  )
}


export const getFileImportCount = async function () {
  return this.GET(
    '/studies/registration/file-import',
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
        LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
      ]),
    },
    {},
    process.env.VUE_APP_STUDIES_HOST_V2
  )
}

export const getTotalStorageUsed = async function () {
  return this.GET(
    '/studies/storage',
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
        LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
      ]),
    },
    {},
    process.env.VUE_APP_STUDIES_HOST_V2
  )
}
