var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "measurement-rows",
      class: { "full-rows": _vm.hasFullRows },
      attrs: { "no-gutters": "" },
    },
    [
      _c("popover-measurement", {
        attrs: {
          reading: _vm.readingPm,
          label: _vm.$t("readings." + _vm.pmReadingType),
          unitsLabel: _vm.pmReadingUnitLabel,
          imageSrc: "/img/measurements/pm.svg",
        },
      }),
      _c("popover-measurement", {
        attrs: {
          reading: _vm.readingTemp,
          label: _vm.$t("readings.temp"),
          unitsLabel: _vm.tempReadingUnitLabel,
          imageSrc: "/img/measurements/temp.svg",
        },
      }),
      _vm.isMeasurementVisible(_vm.types.rh)
        ? _c("popover-measurement", {
            attrs: {
              isSupported: _vm.isMeasurementSupported(_vm.types.rh),
              reading: _vm.readingHumidity,
              label: _vm.$t("readings.rh"),
              unitsLabel: _vm.$t("units.percent"),
              imageSrc: "/img/measurements/rh.svg",
            },
          })
        : _vm._e(),
      _vm.isMeasurementVisible(_vm.types.baro_inhg)
        ? _c("popover-measurement", {
            attrs: {
              isSupported: _vm.isMeasurementSupported(_vm.types.baro_inhg),
              reading: _vm.readingBp,
              label: _vm.$t("readings.baro_inhg"),
              unitsLabel: _vm.$t("units." + _vm.pressureReadingUnitLabel),
              imageSrc: "/img/measurements/bp.svg",
            },
          })
        : _vm._e(),
      _vm.isMeasurementVisible(_vm.types.co2)
        ? _c("popover-measurement", {
            attrs: {
              isSupported: _vm.isMeasurementSupported(_vm.types.co2),
              reading: _vm.readingCo2,
              label: _vm.$t("readings.co2"),
              unitsLabel: _vm.$t("units.ppm"),
              imageSrc: "/img/measurements/co2.svg",
            },
          })
        : _vm._e(),
      _vm.isMeasurementVisible(_vm.types.tvoc)
        ? _c("popover-measurement", {
            attrs: {
              isSupported: _vm.isMeasurementSupported(
                _vm.types.tvoc,
                _vm.SlugsEnum.GasReadTVOC
              ),
              reading: _vm.readingTvoc,
              tooltip: _vm.getUpgradeTooltip(
                _vm.types.tvoc,
                _vm.SlugsEnum.GasReadTVOC
              ),
              label: _vm.$t("readings.tvoc"),
              unitsLabel: _vm.$t("units.mgm3"),
              imageSrc: "/img/measurements/voc.svg",
            },
          })
        : _vm._e(),
      _vm.isMeasurementVisible(_vm.types.ch2o)
        ? _c("popover-measurement", {
            attrs: {
              isSupported: _vm.isMeasurementSupported(
                _vm.types.ch2o,
                _vm.SlugsEnum.GasReadCH2O
              ),
              reading: _vm.readingCh2o,
              tooltip: _vm.getUpgradeTooltip(
                _vm.types.ch2o,
                _vm.SlugsEnum.GasReadCH2O
              ),
              label: _vm.$t("readings.ch2o"),
              unitsLabel: _vm.$t("units.ppb"),
              imageSrc: "/img/measurements/ch2o.svg",
            },
          })
        : _vm._e(),
      _vm.isMeasurementVisible(_vm.types.co)
        ? _c("popover-measurement", {
            attrs: {
              isSupported: _vm.isMeasurementSupported(
                _vm.types.co,
                _vm.SlugsEnum.GasReadCO
              ),
              reading: _vm.readingCo,
              tooltip: _vm.getUpgradeTooltip(
                _vm.types.co,
                _vm.SlugsEnum.GasReadCO
              ),
              label: _vm.$t("readings.co"),
              unitsLabel: _vm.$t("units.ppm"),
              imageSrc: "/img/measurements/co.svg",
            },
          })
        : _vm._e(),
      _vm.isMeasurementVisible(_vm.types.so2)
        ? _c("popover-measurement", {
            attrs: {
              isSupported: _vm.isMeasurementSupported(_vm.types.so2),
              reading: _vm.readingSo2,
              tooltip: _vm.getUpgradeTooltip(
                _vm.types.so2,
                _vm.SlugsEnum.GasReadSO2
              ),
              label: _vm.$t("readings.so2"),
              unitsLabel: _vm.$t("units.ppb"),
              imageSrc: "/img/measurements/so2.svg",
            },
          })
        : _vm._e(),
      _vm.isMeasurementVisible(_vm.types.no2)
        ? _c("popover-measurement", {
            attrs: {
              isSupported: _vm.isMeasurementSupported(_vm.types.no2),
              reading: _vm.readingNo2,
              tooltip: _vm.getUpgradeTooltip(
                _vm.types.no2,
                _vm.SlugsEnum.GasReadNO2
              ),
              label: _vm.$t("readings.no2"),
              unitsLabel: _vm.$t("units.ppb"),
              imageSrc: "/img/measurements/no2.svg",
            },
          })
        : _vm._e(),
      _vm.isMeasurementVisible(_vm.types.o3)
        ? _c("popover-measurement", {
            attrs: {
              isSupported: _vm.isMeasurementSupported(_vm.types.o3),
              reading: _vm.readingO3,
              label: _vm.$t("readings.o3"),
              tooltip: _vm.getUpgradeTooltip(
                _vm.types.o3,
                _vm.SlugsEnum.GasReadO3
              ),
              unitsLabel: _vm.$t("units.ppb"),
              imageSrc: "/img/measurements/o3.svg",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }