<template>
  <base-dialog
    :open="open"
    @close="close"
    :fullscreen="$vuetify.breakpoint.xs"
    :persistentOverlay="true"
    customCss="pop-up-message-dialog"
  >
    <template v-slot:header>
      <v-card-title data-cy="popUpMessageHeader">
        {{ title }}
      </v-card-title>
    </template>
    <v-card-text>
      <div v-if="messages && messages.length" data-cy="popUpMessageContent">
        <p v-for="(message, index) in messages" :key="index">{{ message }}</p>
      </div>
    </v-card-text>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="close"
        class="primary white--text"
        text
        data-cy="popUpMessageDone"
        >{{ $t('buttons.done') }}</v-btn
      >
    </template>
  </base-dialog>
</template>

<script>
import Dialog from './Dialog.vue'

export default {
  name: 'PopUpMessageDialog',
  components: { 'base-dialog': Dialog },
  props: {
    open: Boolean,
    title: String,
    messages: Array,
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    padding: 16px;
  }
  .v-card__title {
    font-weight: 300;
    padding: 8px 24px 16px 16px;
  }
  .v-card__subtitle {
    padding: 8px 24px 16px 16px;
  }
  .v-card__text {
    p {
      margin: 0 0 16px;
    }
  }
  .v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
  .v-card__actions {
    margin-top: 24px;
  }
}
</style>
