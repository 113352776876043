var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "measurement-container-large" },
    [
      _c("popover-measurement", {
        attrs: {
          reading: _vm.readingPm,
          label: _vm.$t("readings." + _vm.pmReadingType),
          unitsLabel: _vm.pmReadingUnitLabel,
          imageSrc: "/img/measurements/pm.svg",
          largeMeasurement: true,
        },
      }),
      _c("popover-measurement", {
        attrs: {
          reading: _vm.readingTemp,
          label: _vm.$t("readings.temp"),
          unitsLabel: _vm.tempReadingUnitLabel,
          imageSrc: "/img/measurements/temp.svg",
          largeMeasurement: true,
        },
      }),
      _c("popover-measurement", {
        attrs: {
          reading: _vm.readingHumidity,
          label: _vm.$t("readings.rh"),
          unitsLabel: _vm.$t("units.percent"),
          imageSrc: "/img/measurements/rh.svg",
          largeMeasurement: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }