import { getLatestTimestamp } from '../helpers/common/helper'
import { subscriptionTypes } from '@/store/subscriptions/utils'

const DAY_MS = 24 * 60 * 60 * 1000
const HOUR_MS = 60 * 60 * 1000
const DISCONNECTED_DEVICE_MILLISECONDS = 5 * 60 * 1000

export const activeLastDay = (date) => {
  return wasActiveWithinTime(date, DAY_MS)
}

export const activeLastHour = (date) => {
  return wasActiveWithinTime(date, HOUR_MS)
}

export const wasActiveWithinTime = (date, timeInMilliseconds) => {
  if (date && timeInMilliseconds && Number.isInteger(timeInMilliseconds)) {
    const lastContacted = new Date(date)
    // checks for invalid date
    if (isNaN(lastContacted)) {
      return false
    }

    return new Date().getTime() - lastContacted < timeInMilliseconds
  } else {
    return false
  }
}

export const validateTimestamp = (timestamp, noTS) => {
  // make sure that the timestamp has positive milliseconds since epoch
  return new Date(timestamp).getTime() > 0 ? timestamp : noTS
}

// Validates that the last communication time doesn't exceed 2 minutes ago.
export const isDeviceConnected = (lastCommunicationTime) => {
  if (lastCommunicationTime) {
    const lastCommunicationDate = new Date(lastCommunicationTime)
    // checks for invalid date
    if (isNaN(lastCommunicationDate)) {
      return false
    }

    return (
      lastCommunicationDate.getTime() >
      new Date().getTime() - DISCONNECTED_DEVICE_MILLISECONDS
    )
  }

  return false
}

export const getDeviceSubscriptionType = (device) => {
  let isShared = device?.is_shared
  let hasDataServices = device?.activations?.dataservices !== undefined
  let websiteSubscription = device?.activations?.website?.subType
  if (isShared) {
    return subscriptionTypes.NOT_APPLICABLE
  }
  const hasTsiLinkPremium = websiteSubscription === subscriptionTypes.WEBSITE_PREMIUM
  const hasTsiLinkBasic = websiteSubscription === subscriptionTypes.WEBSITE_BASIC
  const hasTsiLinkFree = websiteSubscription === subscriptionTypes.FREE_TIER_API_RESPONSE
  const hasDeviceSmartBridge = websiteSubscription === subscriptionTypes.DEVICE_SMART_BRIDGE
  const hasStudies = websiteSubscription === subscriptionTypes.STUDIES

  switch (true) {
    case hasDataServices && (hasTsiLinkBasic || hasTsiLinkPremium):
      return subscriptionTypes.DUAL
    case hasDataServices && hasTsiLinkFree:
      return subscriptionTypes.DATA_AND_FREE
    case hasDataServices:
      return subscriptionTypes.DATA_SERVICES
    case hasTsiLinkPremium:
      return subscriptionTypes.WEBSITE_PREMIUM
    case hasTsiLinkBasic:
      return subscriptionTypes.WEBSITE_BASIC
    case hasTsiLinkFree:
      return subscriptionTypes.FREE_TIER
    case hasDeviceSmartBridge:
      return subscriptionTypes.DEVICE_SMART_BRIDGE
    case hasStudies:
      return subscriptionTypes.STUDIES
    default:
      return subscriptionTypes.NOT_APPLICABLE
  }
}

export const getLastDeviceCommunicationTimestamp = (iotCoreData) => {
  if (iotCoreData && typeof iotCoreData === 'object') {
    return getLatestTimestamp([
      iotCoreData.last_event_time,
      iotCoreData.last_heartbeat_time,
      iotCoreData.last_state_time,
    ])
  }

  return null
}

export const getLastConnectedDeviceCommunicationTimestamp = (lastTelemetryTimestamp, lastStateTimestamp) => {
  return getLatestTimestamp([
    lastTelemetryTimestamp,
    lastStateTimestamp,
  ])
}
