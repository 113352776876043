import { UnitOfMeasure } from '@fusion/units'

export function AddUserSetting(settingObj) {
  const settings = {}
  settings['bluesky'] = settingObj
  return settings
}

export const getPMSelectedUnit = (store) => {
  return (
    store?.state?.userProfile?.settings?.pmUnit ||
    UnitOfMeasure.MICROGRAMS_PER_CUBIC_METER
  )
}

export const getTemperatureSelectedUnit = (store) => {
  return (
    store?.state?.userProfile?.settings?.tempUnit || UnitOfMeasure.DEGREES_C
  )
}

export const getPressureSelectedUnit = (store) => {
  return (
    store?.state?.userProfile?.settings?.pressureUnit ||
    UnitOfMeasure.INCH_MERCURY
  )
}

export const filterMovedToAnotherDeviceMessages = (
  movedToAnotherDeviceMessages,
  movedToAnotherDeviceMessage
) => {
  if (
    movedToAnotherDeviceMessages?.length &&
    movedToAnotherDeviceMessage?.connectedDeviceSerial
  ) {
    return movedToAnotherDeviceMessages.filter((message) => {
      return (
        message.connectedDeviceSerial !==
        movedToAnotherDeviceMessage.connectedDeviceSerial
      )
    })
  }

  return movedToAnotherDeviceMessages
}
