<template>
  <div
    :style="`height: calc(100vh - ${
      this.$vuetify.breakpoint.mdAndUp ? '64px' : '56px'
    });`"
  >
    <v-map></v-map>
    <div v-if="!dialogs.reactivateAccount" class="add-device">
      <register-move-device />
      <register-device-map-button @open="dialogs.registerDevice = true" />
      <register-device
        :open="dialogs.registerDevice"
        :deviceSerial="''"
        :deviceModel="''"
        @close="dialogs.registerDevice = false"
        @deviceCreated="onDeviceCreated"
      />
    </div>
    <div class="map-legend">
      <map-legend />
    </div>
    <fusion-reactivate-account
      :open="dialogs.reactivateAccount"
      @close="this.closeReactivateAccount"
    ></fusion-reactivate-account>
    <div v-if="!dialogs.reactivateAccount">
      <fusion-latest-release-note-dialog
        :open="dialogs.releaseNotes"
        @close="dialogs.releaseNotes = false"
      />
      <subscription-expired
        :open="dialogs.subscriptionExpired"
        @close="dialogs.subscriptionExpired = false"
      />
      <devices-updated
        :devices="devicesUpdated"
        :open="dialogs.devicesUpdated"
        @close="dialogs.devicesUpdated = false"
      />
    </div>
    <fusion-latest-release-note-dialog
      :open="dialogs.releaseNotes"
      @close="dialogs.releaseNotes = false"
    />
    <subscription-expired
      :open="dialogs.subscriptionExpired"
      @close="dialogs.subscriptionExpired = false"
    />
    <devices-updated
      :devices="devicesUpdated"
      :open="dialogs.devicesUpdated"
      @close="dialogs.devicesUpdated = false"
    />
  </div>
</template>

<script>
import { accountExists, emailVerified, isAuthorized } from '@fusion/auth'
import { mixins } from '@fusion/saas-components'
import semver from 'semver'
import RegisterDevice from '../components/registerDevice/registerDialog'
import mapButton from '../components/registerDevice/mapButton'
import moveDevice from '../components/registerDevice/moveDevice'
import VMap from '../components/Map'
import MapLegend from '../components/MapLegend.vue'
import { AddUserSetting } from '../services/user-settings'
import SubscriptionExpired from '../components/dialogs/SubscriptionExpired.vue'
import DevicesUpdatedDialog from '../components/dialogs/DevicesUpdatedDialog'
import { getModelsMetaData } from '../services/device-service'

Vue.component('register-device', RegisterDevice)
Vue.component('v-map', VMap)
Vue.component('map-legend', MapLegend)
Vue.component('subscription-expired', SubscriptionExpired)
Vue.component('devices-updated', DevicesUpdatedDialog)

export default {
  name: 'HomePrivate',
  mixins: [isAuthorized, accountExists, emailVerified, mixins.reactivateAccount],
  components: {
    'register-device-map-button': mapButton,
    'register-move-device': moveDevice,
  },
  data() {
    return {
      dialogs: {
        releaseNotes: false,
        registerDevice: false,
        subscriptionExpired: false,
        devicesUpdated: false,
      },
      unwatch: null,
    }
  },
  async mounted() {
    // if the user has never visited before, setup first visit (show register device)
    if (this.visited === false) {
        await this.setupFirstVisit()
    }
  },
  watch: {
    viewedSubscriptionExpired() {
      //only try if user has already visited
      if (this.$store.state.user.settings.visited) {
        //only do this if the user has not viewed subscription expired
        if (this.viewedSubscriptionExpired === false) {
          //show the dialog
          this.dialogs.subscriptionExpired = true
          //update the setting that we viewed it
          this.updateViewedSubscriptionExpired(true)
        }
      }
    },
    lastVersion() {
      if (!this.$store.state.user.settings.visited) {
        this.updateLVVersion()
        return
      }

      const lrm = this.getLastReleaseMinorV()

      if (semver.satisfies(this.$store.state.releaseVersion, '>' + lrm)) {
        this.updateLVVersion()
        this.dialogs.releaseNotes = true
        return
      }

      if (semver.gt(this.$store.state.releaseVersion, this.lastVersion)) {
        this.updateLVVersion()
      }
    },
    devicesUpdated() {
      //only try if user has already visited
      if (this.$store.state.user.settings.visited) {
        //only do this if the user has not viewed subscription expired
        if (this.devicesUpdated.length > 0) {
          //show the dialog
          this.dialogs.devicesUpdated = true
          //update the setting that we viewed it
          this.updateDevicesUpdated()
        }
      }
    },
    async visited(visited) {
      if (visited === false) {
        await this.setupFirstVisit()
      }
    }
  },
  computed: {
    lastVersion() {
      return this.$store.getters['user/getLastViewedVersion']
    },
    viewedSubscriptionExpired() {
      return this.$store.getters['user/getViewedSubscriptionExpired']
    },
    devicesUpdated() {
      return this.$store.getters['user/getDevicesUpdated']
    },
    visited() {
      return this.$store.getters['user/getVisited']
    }
  },
  methods: {
    async setupFirstVisit() {
      await this.getModelsMeta()
      await this.getUserDevices()
      await this.$store.dispatch('subscriptions/updateSubscriptions', {
        auth: this.$auth,
        api: this.$api,
        devices: [],
      })

      const visitedSetting = AddUserSetting({
        visited: true,
      })
      this.$api.patchUserSettings(visitedSetting)
    },
    async getUserDevices() {
      await this.$store.dispatch('devices/setAllDevices')
    },
    updateDevicesUpdated() {
      const devicesUpdated = []
      const setting = AddUserSetting({
        devicesUpdated,
      })
      this.$api.patchUserSettings(setting)
    },
    updateViewedSubscriptionExpired(value = true) {
      const setting = AddUserSetting({
        viewedSubscriptionExpired: value ? true : false,
      })
      //update store
      this.$store.dispatch('user/updateSettings', setting)
      //update api
      this.$api.patchUserSettings(setting)
    },
    updateLVVersion() {
      if (this.$store.state.releaseVersion) {
        const versionSetting = AddUserSetting({
          lastViewedVersion: this.$store.state.releaseVersion,
        })
        this.$api.patchUserSettings(versionSetting)
      }
    },
    getLastReleaseMinorV() {
      const rv = this.lastVersion
      const sp = rv.split('.')
      const mv = sp.map(this.removePatch).join('.')
      return mv
    },
    removePatch(x, i) {
      if (i == 2) return 'X'
      return x
    },
    startPinActivation(deviceId) {
      const device = {
        deviceId: deviceId,
        coords: this.$store.getters['devices/getCoordsByDeviceId'](deviceId),
      }
      this.$store.dispatch('map/startMoving', device)
    },
    async onDeviceCreated(deviceId) {
      this.startPinActivation(deviceId)
    },
    async getModelsMeta() {
      const modelsData = await getModelsMetaData()
      this.$store.dispatch('devicemodels/setModelsMetaData', modelsData)
    },
  },
}
</script>

<style lang="scss" scoped>
.add-device {
  position: absolute;
  bottom: 1.6rem;
  right: 1.6rem;
}

.map-legend {
  position: absolute;
  bottom: 0rem;
  left: 1.6rem;

  @media (max-width: 650px) {
    left: 0;
  }
}
</style>
