import Highcharts from 'highcharts/highstock'

const TOLERANCE = 1100 // 1.1 seconds
const WINDOW_SIZE = 3

// default dataset colors used for graph
export const defaultGraphColors = [
  '#00aeef',
  '#ec038a',
  '#f48123',
  '#4fb74a',
  '#4b4bf5',
]

// default value for decimals argument is -1, which highcharts' numberFormat
// method interprets as "keep whatever digits are present in the input value"
export const getTooltipFormatterCallback = (units, decimals=-1) => {
  // format any exponent in the units string
  units = units.replace('^', '<sup>')
  units += '</sup>'

  return function () {
    const value =
      this.series && this.series.options.type === 'flags'
        ? this.point.text
        : Highcharts.numberFormat(this.y, decimals, '.', '') + units
    return (
      `
      <span>${new Date(this.x).toLocaleString()}</span>
      <br />
      <span>
        <b style="color: ${this.series.color};">` +
      value +
      `</b>
      </span>
    `
    )
  }
}

// default Dygraph options
export const defaultGraphOptions = {
  accessibility: {
    enabled: false,
  },
  chart: {
    zoomType: 'x',
    height: null,
    resetZoomButton: {
      position: {
        y: 1,
      },
      relativeTo: 'chart',
    },
  },
  credits: {
    enabled: false,
  },
  boost: {
    usePreallocated: false,
  },

  title: {
    text: '',
  },

  legend: {
    enabled: false,
  },

  subtitle: {
    text: '',
  },

  tooltip: {
    valueDecimals: 2,
    borderColor: '#00aeef',
    useHTML: true,
    formatter: getTooltipFormatterCallback(''),
  },
  time: {
    useUTC: false,
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      millisecond: '%l:%M:%S.%L %p',
      second: '%l:%M:%S %p',
      minute: '%l:%M %p',
      hour: '%l:%M %p',
      day: '%e. %b',
      week: '%e. %b',
      month: "%b '%y",
      year: '%Y',
    },
  },

  yAxis: {
    title: '',
    labels: {
      enabled: true,
    },
  },

  series: [],

  navigator: {
    enabled: true,
    height: 30,
    margin: 16,
    xAxis: {
      dateTimeLabelFormats: {
        millisecond: '%l:%M:%S.%L %p',
        second: '%l:%M:%S %p',
        minute: '%l:%M %p',
        hour: '%l:%M %p',
        day: '%e. %b',
        week: '%e. %b',
        month: "%b '%y",
        year: '%Y',
      },
    },
  },
  scrollbar: {
    enabled: false,
  },
  rangeSelector: {
    enabled: false,
  },
  plotOptions: {
    series: {
      showInNavigator: true,
    },
  },
}

const getInvalidAQIStartTimestamp = (readings) => {
  const timestamps = []
  for (let i = 0; i < readings.length; i++) {
    const timestamp = readings[i][0]
    const aqi = readings[i][1]
    if (aqi === null) {
      if (i === 0) {
        timestamps.push(timestamp)
      } else {
        const prevAqi = readings[i - 1][1]
        if (prevAqi !== null) {
          timestamps.push(timestamp)
        }
      }
    }
  }
  return timestamps
}

export const createFlags = (readings, color) => {
  const invalidAQITimestamp = getInvalidAQIStartTimestamp(readings)
  if (invalidAQITimestamp.length > 0) {
    const flags = {
      type: 'flags',
      data: invalidAQITimestamp.map((t) => {
        return {
          x: t,
          title: '!',
          text: 'Error: AQI Data is not available because the value has exceeded the allowable U.S. AQI range (0-500).',
        }
      }),
      shape: 'circlepin',
      color: color,
      fillColor: color,
      width: 16,
      style: {
        // style for title
        color: 'white',
      },
    }
    return flags
  }
  return null
}

function approxEq(pt1, pt2) {
  return Math.abs(pt1 - pt2) < TOLERANCE
}

export const findGaps = (arr) => {
  let gapTSArr = []
  let intervalChangeTSArr = []
  if (arr.length < WINDOW_SIZE) return [arr, gapTSArr, intervalChangeTSArr]

  let interval = arr[1][0] - arr[0][0]

  for (let i = 1; i <= arr.length - WINDOW_SIZE; i++) {
    const currInterval = arr[i + 1][0] - arr[i][0]
    const nextInterval = arr[i + 2][0] - arr[i + 1][0]
    if (!approxEq(interval, currInterval)) {
      // interval changes and continues at a consistent frequency
      if (approxEq(currInterval, nextInterval)) {
        interval = currInterval
        intervalChangeTSArr.push(arr[i][0])
        // interval changes but does not remain consistent
      } else {
        // insert a new timestamp in the middle of the gap for a null point
        gapTSArr.push(arr[i + 1][0] - (arr[i + 1][0] - arr[i][0]) / 2)
      }
    }
  }
  return [arr, gapTSArr, intervalChangeTSArr]
}

function createPlotLines(from, to, color) {
  return [
    {
      color: color,
      width: 1,
      value: from,
      dashStyle: 'dash',
    },
    {
      color: color,
      width: 1,
      value: to,
      dashStyle: 'dash',
      label: {
        useHTML: true,
        formatter: function () {
          return `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <!-- Outer Circle -->
            <circle cx="9" cy="9" r="9" fill="${color}"/>

            <!-- Exclamation Mark Body -->
            <rect x="8" y="4" width="2" height="6" fill="white"/>

            <!-- Exclamation Mark Dot -->
            <rect x="8" y="12" width="2" height="2" fill="white"/>
          </svg>`
        },
        rotation: 0,
        x: -9,
        y: 2,
      },
    },
  ]
}

export const getPlotLines = (readings, seriesColor) => {
  const plotLines = []
  if (readings.length < WINDOW_SIZE) return plotLines

  readings.forEach((currentPoint, idx) => {
    if (idx > 1 && currentPoint[1] === null) {
      // Pull out the first element (since the data points are [time, value])
      const [preGapTime] = readings[idx - 1]
      const [postGapTime] = readings[idx + 1]
      plotLines.push(...createPlotLines(preGapTime, postGapTime, seriesColor))
    }
  })
  return plotLines
}

export const getCustomTooltipPosition = (event, graph, labelWidth) => {
  let x,
    y = 0
  if (event && typeof event === 'object' && graph) {
    const { pageX, pageY, offsetY } = event
    const { plotLeft, plotTop, containerWidth } = graph

    // Get the bounding rectangle of the chart container
    const containerRect = graph.container.getBoundingClientRect()

    // Calculate position of the data label relative to the container
    x = pageX - containerRect.left - plotLeft + 50
    const labelRight = x + labelWidth
    // If the label is out of the graph container
    if (labelRight > containerWidth) {
      x = x - (labelRight - containerWidth)
    }

    y = pageY - containerRect.top - plotTop + (30 - Math.abs(offsetY))
  }

  return { x, y }
}

const renderCustomTooltip = (graph, tooltipConfig = {}) => {
  if (graph && typeof graph.renderer === 'object') {
    return graph.renderer
      .label(tooltipConfig.labelText)
      .attr(tooltipConfig.attr)
      .css(tooltipConfig.css)
      .add(graph.rGroup)
      .attr({
        x: tooltipConfig.x,
        y: tooltipConfig.y,
      })
  }

  return null
}

export const renderNoTimePeriodTooltip = (graph, x, y) => {
  return renderCustomTooltip(graph, {
    labelText: 'No data exists for previous time period',
    attr: {
      'stroke-width': 1,
      zIndex: 10,
      stroke: 'rgba(0, 0, 0, 0.25)',
      padding: 8,
      r: 5,
      fill: 'rgba(255, 255, 255, 0.85)',
      shadow: true,
    },
    css: {
      color: '#333',
      fontSize: '12px',
    },
    x,
    y,
  })
}
