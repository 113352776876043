<template>
  <div class="device-conditions-wrapper">
    <device-condition
      v-for="item in formattedDeviceConditionsArray"
      :key="item.label"
      :label="item.label"
      :dataCy="item.dataCy"
      :showDeviceConditionError="item.showDeviceConditionError"
      :showNotAvailableIcon="showNotAvailableIcon"
    />
  </div>
</template>

<script>
import DeviceCondition from './DeviceCondition.vue'
import { activeLastDay } from '@/services/device-status'

const DEVICE_CONDITIONS_CONFIGURATION = [
  {
    value: 0,
    label: 'widget.currentReadings.flow',
    dataCy: 'flowDeviceCondition',
  },
  {
    value: 1,
    label: 'widget.currentReadings.laser',
    dataCy: 'laserDeviceCondition',
  },
  {
    value: 2,
    label: 'widget.currentReadings.filter',
    dataCy: 'filterDeviceCondition',
  },
]

export default {
  name: 'DeviceConditions',
  components: {
    'device-condition': DeviceCondition,
  },
  props: {
    deviceConditionsArray: Array,
    latestTelemetryTimestamp: String,
  },
  computed: {
    showNotAvailableIcon() {
      return !this.deviceConditionsArray || !activeLastDay(this.latestTelemetryTimestamp) // or if we dont have recent activity
    },
    formattedDeviceConditionsArray() {
      return DEVICE_CONDITIONS_CONFIGURATION.map((configuration) => {
        return {
          label: this.$t(configuration.label),
          dataCy: configuration.dataCy,
          showDeviceConditionError: this.showDeviceConditionError(
            configuration.value
          ),
        }
      })
    },
  },
  methods: {
    showDeviceConditionError(deviceConditionValue) {
      return !!(
        this.deviceConditionsArray?.length &&
        this.deviceConditionsArray.indexOf(deviceConditionValue) !== -1
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.device-conditions-wrapper {
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
  gap: 0 11%;
  margin-right: 8%;
}
</style>
