<template>
  <div class="one-measurement-wrapper">
    <popover-measurement
      :reading="currentMeasurement.reading"
      :label="currentMeasurement.label"
      :unitsLabel="currentMeasurement.unitsLabel"
      :imageSrc="currentMeasurement.imageSrc"
      :showInactiveReading="showInactiveReadings"
      :customClass="`${size}-size bolded-reading grey-empty-reading`"
    />
  </div>
</template>

<script>
import popoverMeasurement from '../content/measurements/popoverMeasurement.vue'
import { convertArrayToObject } from '@/services/data-conversion'
import { measurementTypesKey } from '@/services/device-measurements.js'
import { getPopoverMeasurementData } from '@/helpers/widgets/helper'

export default {
  name: 'OneMeasurementMonitoring',
  components: {
    'popover-measurement': popoverMeasurement,
  },
  props: {
    deviceId: { type: String, default: '' },
    selectedParameter: Number,
    measurementReadings: Object,
    size: { type: String, default: 'large' },
    showInactiveReadings: Boolean,
  },
  computed: {
    model() {
      return this.$store.getters['devices/getModelByDeviceId'](this.deviceId)
    },
    submodel() {
      return this.$store.getters['devices/getSubmodelByDeviceId'](this.deviceId)
    },
    deviceType() {
      return this.$store.getters['devices/getDeviceTypeByDeviceId'](
        this.deviceId
      )
    },
    readings() {
      return (
        this.measurementReadings ||
        convertArrayToObject(
          this.$store.getters['devices/getSensorReadingsByDeviceId'](
            this.deviceId
          ),
          'name'
        )
      )
    },
    currentMeasurement() {
      return getPopoverMeasurementData(
        this.$t.bind(this),
        {
          measurementName: measurementTypesKey[this.selectedParameter],
          deviceType: this.deviceType,
          readings: this.readings,
        },
        null
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.one-measurement-wrapper {
  ::v-deep .popover-measurement {
    display: block;

    .reading {
      justify-content: center;
    }

    .label {
      width: 100%;
      justify-content: center;
    }

    img {
      height: unset;
    }
  }

  ::v-deep .empty-reading.large-size {
    .reading {
      margin-top: 0 !important;
    }
  }

  ::v-deep .empty-reading.medium-size {
    .reading {
      font-size: 2.8rem;
    }
  }

  ::v-deep .large-size {
    margin: 35px 0;
  }

  ::v-deep .medium-size {
    margin: 12px 0;
  }
}
</style>
