var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "dark-background" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                color: "white",
                "data-cy": "cancelDateSelectionButton",
              },
              on: { click: _vm.cancel },
            },
            [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                color: "white",
                "data-cy": "saveDateSelectionButton",
              },
              on: { click: _vm.applyCustomDateRange },
            },
            [_vm._v(" " + _vm._s(_vm.$t("buttons.save")) + " ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "range-container" }, [
        _c("div", { staticClass: "range-label" }, [
          _vm._v(_vm._s(_vm.$t("map.historyDialog.datePicker.rangeLabel"))),
        ]),
        _c(
          "div",
          { staticClass: "range-value" },
          [
            _c(
              "span",
              {
                class: { "default-value": !_vm.customDateRange.start },
                attrs: { "data-cy": "customStartDate" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.formatDate(
                        _vm.customDateRange.start,
                        _vm.$t("map.historyDialog.datePicker.defaultRangeStart")
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "span",
              { class: { "default-value": !_vm.customDateRange.start } },
              [_vm._v(" - ")]
            ),
            _c(
              "span",
              {
                class: { "default-value": !_vm.customDateRange.end },
                attrs: { "data-cy": "customEndDate" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.formatDate(
                        _vm.customDateRange.end,
                        _vm.$t("map.historyDialog.datePicker.defaultRangeEnd")
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _vm.customDateRangeModel.length
              ? _c(
                  "v-icon",
                  {
                    staticClass: "clear-icon",
                    attrs: { small: "", "data-cy": "clearDateSelectionButton" },
                    on: { click: _vm.clearDates },
                  },
                  [_vm._v(" mdi-close-circle-outline ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("v-date-picker", {
        attrs: {
          "full-width": "",
          "no-title": "",
          range: "",
          color: "primary",
          min: _vm.minCustomDate,
          max: _vm.maxCustomDate,
          "data-cy": "datePickerSelector",
        },
        model: {
          value: _vm.customDateRangeModel,
          callback: function ($$v) {
            _vm.customDateRangeModel = $$v
          },
          expression: "customDateRangeModel",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }