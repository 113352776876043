<template>
  <div class="measurement-container-large">
    <popover-measurement
      :reading="readingPm"
      :label="$t(`readings.${pmReadingType}`)"
      :unitsLabel="pmReadingUnitLabel"
      imageSrc="/img/measurements/pm.svg"
      :largeMeasurement="true"
    />
    <popover-measurement
      :reading="readingTemp"
      :label="$t(`readings.temp`)"
      :unitsLabel="tempReadingUnitLabel"
      imageSrc="/img/measurements/temp.svg"
      :largeMeasurement="true"
    />
    <popover-measurement
      :reading="readingHumidity"
      :label="$t(`readings.rh`)"
      :unitsLabel="$t('units.percent')"
      imageSrc="/img/measurements/rh.svg"
      :largeMeasurement="true"
    />
  </div>
</template>

<script>
import popoverMeasurement from '../popoverMeasurement.vue'
import { convertArrayToObject } from '@/services/data-conversion'
import {
  getPMSelectedUnit,
  getTemperatureSelectedUnit,
} from '@/services/user-settings'
import {
  getMeasurementTypes,
  getReadingUnitLabel,
} from '@/components/devicePopover/utils'
import {
  getReadingPM,
  getReadingTemperature,
  getReadingValue,
} from '@/services/unit-conversion.js'

export default {
  props: {
    deviceId: String,
    sensorReadings: Array,
  },
  components: {
    'popover-measurement': popoverMeasurement,
  },
  computed: {
    deviceType: function () {
      return this.$store.getters['devices/getDeviceTypeByDeviceId'](
        this.deviceId
      )
    },
    readings: function () {
      return convertArrayToObject(this.sensorReadings, 'name')
    },
    pmReadingType: function () {
      const measType = this.$store.getters['map/getMeasurementType']
      return getMeasurementTypes(measType)
    },
    pmReadingUnit: function () {
      return getPMSelectedUnit(this.$store)
    },
    pmReadingUnitLabel: function () {
      return getReadingUnitLabel(this.pmReadingUnit)
    },
    tempReadingUnit: function () {
      return getTemperatureSelectedUnit(this.$store)
    },
    tempReadingUnitLabel: function () {
      return getReadingUnitLabel(this.tempReadingUnit)
    },
    readingPm: function () {
      return getReadingPM(
        this.readings,
        this.pmReadingType,
        this.pmReadingUnit,
        this.deviceType
      )
    },
    readingTemp: function () {
      return getReadingTemperature(
        this.readings,
        this.tempReadingUnit,
        this.deviceType
      )
    },
    readingHumidity: function () {
      return getReadingValue(this.readings, 'rh_percent', this.deviceType, true)
    },
  },
}
</script>
<style lang="scss" scoped>
.measurement-container-large {
  margin-top: 16px;

  @media (max-width: 460px) {
    padding-bottom: 40px;
  }
}
</style>
