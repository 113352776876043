<template>
  <GmapMarker
    v-if="!isPublicInactive"
    :position="coords"
    :icon="icon"
    :label="label"
    :clickable="clickable && !widget"
    :draggable="moving"
    :visible="visible"
    :zIndex="zIndex"
    :opacity="opacity"
    data-cy="marker"
    @click="clicked"
    :title="title"
    @dragend="dragend($event)"
  />
</template>

<script>
import { getAQIStyles, aqiValid, buildIconSVG } from '../services/map'
import { measurementTypes } from '../store/map/utils'
import { getReadings } from './devicePopover/utils'
import { getPermissionsByDeviceId } from '../services/subscriptions'
import { SlugsEnum } from '../permissions/SlugsEnum'
import { getUserAccountId } from '../helpers/loginas/logInAsHelper'

export default {
  props: {
    deviceId: String,
    moving: Boolean,
    widget: Boolean,
    clickable: Boolean,
    zIndex: Number,
    opacity: Number,
  },
  data() {
    return {
      accountId: null,
    }
  },
  async mounted() {
    this.accountId = await getUserAccountId(this.$auth)
  },
  computed: {
    id() {
      return this.deviceId ? this.deviceId : ''
    },
    deviceType() {
      if (this.isMine) return this.$api.deviceType.USER
      if (this.isShared) return this.$api.deviceType.SHARED

      return this.$api.deviceType.PUBLIC
    },
    deviceModel() {
      return this.$store.getters['devices/getModelByDeviceId'](this.id)
    },
    isMine() {
      return (
        this.accountId ===
        this.$store.getters['devices/getAccountByDeviceId'](this.id)
      )
    },
    isShared() {
      if (this.isMine) return false
      return this.$store.getters['devices/getIsSharedByDeviceId'](this.id)
    },
    isIndoor() {
      return this.$store.getters['devices/getIsIndoorByDeviceId'](this.id)
    },
    isPublic() {
      return this.$store.getters['devices/getIsPublicByDeviceId'](this.id)
    },
    isDeviceConnected() {
      return this.$store.getters['devices/getIsConnectedByDeviceId'](this.id)
    },
    badge() {
      return this.$store.getters['alerts/getNotificationsNumByDeviceId'](
        this.id
      )
    },
    isPublicInactive() {
      return !this.isMine && !this.isShared && !this.isDeviceConnected
    },
    currentReading() {
      if (this.isDeviceConnected) {
        const type = this.getType(this.$store.getters['map/getMeasurementType'])
        const readings = this.$store.getters[
          'devices/getSensorReadingsByDeviceId'
        ](this.id)
        return getReadings(readings, undefined, undefined)[type]
      }
      return null
    },
    lastTelemetryTimestamp() {
      return this.$store.getters['devices/getLastTelemetryTimestampByDeviceId'](
        this.id
      )
    },
    icon() {
      const productFamily = this.$store.getters[
        'devicemodels/getModelProductFamily'
      ](this.deviceModel)
      return buildIconSVG(
        productFamily,
        this.currentReading,
        this.isIndoor,
        this.moving,
        this.badge
      )
    },
    coords() {
      const nCoords = this.$store.getters['devices/getCoordsByDeviceId'](
        this.id
      )
      return nCoords && nCoords.lat ? nCoords : { lat: NaN, lng: NaN }
    },
    label() {
      if (!this.moving && aqiValid(this.currentReading)) {
        const styles = getAQIStyles(this.currentReading)

        return {
          text: `${this.currentReading}`,
          color: styles.color,
          fontSize: '12px',
          fontWeight: '600',
        }
      } else {
        if (this.currentReading == null) {
          return {
            text: '!',
            color: '#000000',
            fontSize: '16px',
            fontWeight: '700',
          }
        }
      }

      return ''
    },
    visibleDeviceModels() {
      return this.$store.getters['map/getDeviceModels']
    },
    visible() {
      let visibility = true
      if (this.moving) return true
      if (this.isIndoor && !this.$store.state.map.shownSettings.showIndoor) {
        visibility = false
      }

      if (!this.isIndoor && !this.$store.state.map.shownSettings.showOutdoor) {
        visibility = false
      }

      if (this.isMine && !this.$store.state.map.shownSettings.showMine) {
        visibility = false
      }

      if (
        !this.isMine &&
        !this.isShared &&
        this.isPublic &&
        !this.$store.state.map.shownSettings.showPublic
      ) {
        visibility = false
      }

      if (
        !this.isMine &&
        this.isShared &&
        !this.$store.state.map.shownSettings.showShared
      ) {
        visibility = false
      }

      if (
        !this.visibleDeviceModels ||
        !this.visibleDeviceModels.includes(this.deviceModel)
      ) {
        visibility = false
      }

      if (!this.hasPermissions) {
        visibility = false
      }

      if (!visibility) this.$emit('closed', this.id)

      return visibility
    },
    serial() {
      return this.$store.getters['devices/getSerialByDeviceId'](this.id)
    },
    hasPermissions() {
      //only check if it is ours
      if (!this.isMine) {
        return true
      }

      // Permission allowance is now checked via matching slug associated with roles on a per device basis
      const deviceSlugPermissions = getPermissionsByDeviceId(this.deviceId)
      const deviceHasSlugPermissions = deviceSlugPermissions.includes(
        SlugsEnum.MapViewPin
      )

      //has device permissions or in the Free Tier
      if (deviceHasSlugPermissions || this.deviceInFreeTier) {
        return true
      }

      //no permissions for you!
      return false
    },
    deviceInFreeTier() {
      //if there are devices in free tier
      if (this.$store.getters['subscriptions/getDeviceFreeTier']) {
        //then check if this device is in there
        if (
          this.$store.getters['subscriptions/getDeviceFreeTierByDeviceSerial'](
            this.serial
          )
        ) {
          return true
        }
      }
      return false
    },
    title() {
      // Serial numbers are present only for owned and shared devices
      return this.serial ? `Map pin for device ${this.serial}` : ''
    }
  },
  methods: {
    clicked() {
      this.$emit('clicked', {
        deviceId: this.deviceId,
        deviceType: this.deviceType,
        coords: this.coords,
      })
    },
    dragend(event) {
      const lat = parseFloat(event.latLng.lat().toFixed(8))
      const lng = parseFloat(event.latLng.lng().toFixed(8))

      this.$store.dispatch('devices/updateDeviceCoords', {
        deviceId: this.id,
        coords: { lat, lng },
      })
    },
    getType(measurementType) {
      switch (measurementType) {
        case measurementTypes.pm25:
          return 'pm2.5 aqi'
        case measurementTypes.pm10:
          return 'pm10 aqi'
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
