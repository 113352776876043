var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        title: _vm.$t("map.historyDialog.exportDialog.title"),
        persistentOverlay: true,
        icon: "mdi-file-download",
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u(
        [
          !_vm.downloading
            ? {
                key: "actions",
                fn: function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "black--text",
                        attrs: {
                          outlined: "",
                          text: "",
                          "data-cy": "closeExportData",
                        },
                        on: { click: _vm.close },
                      },
                      [_vm._v(_vm._s(_vm.$t("buttons.close")))]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary white--text",
                        attrs: {
                          text: "",
                          "data-cy": "exportDataButton",
                          disabled: _vm.publicOnlyWarning,
                        },
                        on: { click: _vm.exportCsv },
                      },
                      [_vm._v(_vm._s(_vm.$t("buttons.export")))]
                    ),
                  ]
                },
                proxy: true,
              }
            : {
                key: "actions",
                fn: function () {
                  return [
                    _c("v-spacer"),
                    _vm.downloading
                      ? _c(
                          "v-btn",
                          {
                            staticClass:
                              "primary white--text export-data-done-button",
                            attrs: {
                              "data-cy": "closeExportDataDialogButton",
                              text: "",
                            },
                            on: { click: _vm.close },
                          },
                          [_vm._v(_vm._s(_vm.$t("buttons.done")))]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
        ],
        null,
        true
      ),
    },
    [
      _vm.downloading
        ? _c("v-card", { staticClass: "padding-top-16", attrs: { flat: "" } }, [
            _c("span", { staticClass: "message" }, [
              _vm._v(_vm._s(_vm.$t("map.historyDialog.exportDialog.message"))),
            ]),
          ])
        : _vm._e(),
      _c("v-card-subtitle", [
        !_vm.downloading
          ? _c("div", [
              _c("span", { attrs: { "data-cy": "maxExportDaysText" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("map.historyDialog.exportDialog.subtitle", {
                        capacity: _vm.$t(_vm.maxNumberOfDays),
                      })
                    ) +
                    " "
                ),
              ]),
              _vm.subscriptionMixedWarning
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "map.historyDialog.exportDialog.subscriptionMixedWarning"
                          )
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      !_vm.downloading
        ? _c(
            "v-card",
            { staticClass: "padding-24", attrs: { flat: "" } },
            [
              _c("div", { staticClass: "form-section-header" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("map.historyDialog.exportDialog.dateRange")) +
                    " "
                ),
              ]),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "fromDate",
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "290px",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    {
                                      attrs: {
                                        label: "From",
                                        placeholder: "MM/DD/YYYY",
                                        "prepend-icon": "mdi-calendar",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.dateStart,
                                        callback: function ($$v) {
                                          _vm.dateStart = $$v
                                        },
                                        expression: "dateStart",
                                      },
                                    },
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2005053641
                      ),
                      model: {
                        value: _vm.fromDate,
                        callback: function ($$v) {
                          _vm.fromDate = $$v
                        },
                        expression: "fromDate",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          min: _vm.minDateStart,
                          max: _vm.maxDateStart,
                          "show-current": false,
                        },
                        on: {
                          input: function ($event) {
                            _vm.fromDate = false
                          },
                        },
                        model: {
                          value: _vm.dateStart,
                          callback: function ($$v) {
                            _vm.dateStart = $$v
                          },
                          expression: "dateStart",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "toDate",
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "290px",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    {
                                      attrs: {
                                        label: "To",
                                        placeholder: "MM/DD/YYYY",
                                        "prepend-icon": "mdi-calendar",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.dateEnd,
                                        callback: function ($$v) {
                                          _vm.dateEnd = $$v
                                        },
                                        expression: "dateEnd",
                                      },
                                    },
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4277701355
                      ),
                      model: {
                        value: _vm.toDate,
                        callback: function ($$v) {
                          _vm.toDate = $$v
                        },
                        expression: "toDate",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          min: _vm.minDateEnd,
                          max: _vm.maxDateEnd,
                          "show-current": false,
                        },
                        on: {
                          input: function ($event) {
                            _vm.toDate = false
                          },
                        },
                        model: {
                          value: _vm.dateEnd,
                          callback: function ($$v) {
                            _vm.dateEnd = $$v
                          },
                          expression: "dateEnd",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-subtitle",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.hasThreeMonthsPermission,
                      expression: "!hasThreeMonthsPermission",
                    },
                  ],
                },
                [
                  !_vm.downloading
                    ? _c(
                        "span",
                        { attrs: { "data-cy": "upgradeAccountText" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("map.historyDialog.upgradeTooltip")
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-actions",
        { staticClass: "warning-container", attrs: { text: "" } },
        [
          _c("v-spacer"),
          _vm.publicOnlyWarning && !_vm.downloading
            ? _c("div", { staticClass: "hint-text red-font" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("map.historyDialog.exportDialog.publicOnlyWarning")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.publicMixedWarning && !_vm.downloading
            ? _c("div", { staticClass: "hint-text red-font" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "map.historyDialog.exportDialog.publicMixedWarning"
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }