<template>
  <v-card
    :class="{
      'widget-fullscreen': isFullscreen,
      widget: !isFullscreen,
      'widget-disconnected-device-theme': enableDisconnectedDeviceTheme,
    }"
  >
    <v-card-title class="widget-header-container vue-draggable-handle">
      <span v-if="hasActions || !isDialog" class="widget-actions">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              class="menu-dropdown black--text"
              text
              v-on="on"
              data-cy="dashboardWidgetDropdown"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <slot name="actions"></slot>
            <!-- Configure -->
            <v-list-item
              v-if="!isDialog"
              @click.stop="dialogs.configure = true"
              link
              data-cy="widgetMenuConfigure"
            >
              <v-icon>mdi-settings</v-icon>
              <v-list-item-title>
                {{ $t('widget.menu.configure') }}
              </v-list-item-title>
            </v-list-item>
            <!-- Remove -->
            <v-list-item
              v-if="!isDialog"
              @click.stop="removeWidget"
              link
              data-cy="widgetMenuRemove"
            >
              <v-icon>mdi-close-circle</v-icon>
              <v-list-item-title>
                {{ $t('widget.menu.remove') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
      <span class="widget-title-container">
        <v-tooltip bottom :max-width="600" :disabled="!isNameOverflowing">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="widget-title" ref="deviceName">
              <span>{{ title }}</span>
            </div>
          </template>
          <span>{{ title }}</span>
        </v-tooltip>
        <div v-if="subtitle" class="widget-subtitle">
          <span>{{ subtitle }}</span
          >&nbsp;
          <span v-if="serial" class="device-serial">{{ serial }}</span>
        </div>
      </span>
      <span class="widget-left-toolbar">
        <slot name="left-toolbar"></slot>
      </span>
      <v-spacer />
      <span class="widget-right-toolbar">
        <slot name="toolbar"></slot>
      </span>
      <span v-if="fullscreen">
        <v-btn
          class="widget-fullscreen-button"
          @click="toggleFullscreen()"
          text
        >
          <v-icon v-if="isFullscreen" color="grey">
            mdi-fullscreen-exit
          </v-icon>
          <v-icon v-else color="grey">mdi-fullscreen</v-icon>
        </v-btn>
      </span>
      <span v-if="isDialog">
        <v-btn @click="close" text data-cy="close">
          <v-icon color="grey">mdi-close</v-icon>
        </v-btn>
      </span>
    </v-card-title>
    <hr class="divider" />
    <v-card-text class="widget-content-container no-drag">
      <slot name="content"></slot>
    </v-card-text>
    <hr v-if="hasFooter" class="divider" />
    <v-card-text v-if="hasFooter" class="widget-footer-container">
      <slot name="footer"></slot>
    </v-card-text>
    <configure-dialog
      :deviceIds="deviceIds"
      :widgetId="widgetId"
      :widgetType="widgetType"
      :widgetMaxDevices="widgetMaxDevices"
      :open="dialogs.configure"
      :editing="true"
      @close="dialogs.configure = false"
      @widgetUpdated="updateWidget($event)"
      data-cy="addWidgetsDialog"
    />
  </v-card>
</template>

<script>
import AddWidgetsDialog from '../dialogs/AddWidgetsDialog.vue'
import throttle from 'lodash/throttle'
import {
  messageTypes,
  SNACKBAR_STATIC_DURATION_MS,
} from '../../services/notifications'
import { isChildOverflowing } from '../../services/html-utilities'
import { HTTPStatus } from '../../api'
import { getUserAccountId } from '../../helpers/loginas/logInAsHelper'

export default {
  components: {
    'configure-dialog': AddWidgetsDialog,
  },
  props: {
    title: String,
    deviceIds: Array,
    widgetId: String,
    widgetType: String,
    widgetMaxDevices: Object,
    subtitle: String, // do not provide if not needed
    fullscreen: Boolean, // if true, the fullscreen button is available. If false, it is not
    observeSize: Boolean, // enable resize observer if true
    enableDisconnectedDeviceTheme: Boolean,
    isDialog: Boolean,
  },
  data() {
    return {
      accountId: null,
      isFullscreen: false,
      dialogs: {
        configure: false,
      },
      isNameOverflowing: false,
    }
  },
  watch: {
    deviceIds(newVal, oldVal) {
      if (newVal.toString() !== oldVal.toString()) {
        const params = {
          widgetId: this.widgetId,
          deviceIds: newVal,
          previousDeviceIds: oldVal,
        }
        this.updateWidget(params)
      }
    },
    allDevices() {
      this.checkDeviceAccess()
    },
  },
  computed: {
    hasNotifications() {
      return this.$store.hasModule('notifications')
    },
    allDevices() {
      return Object.keys(this.$store.state.devices.allDevices).filter((id) =>
        this.allowDeviceWidget(id)
      )
    },
    hasActions() {
      return !!this.$slots['actions']
    },
    hasOneDevice() {
      return !!(
        this.deviceIds &&
        this.deviceIds.length &&
        this.deviceIds.length === 1
      )
    },
    hasFooter() {
      return !!this.$slots['footer']
    },
    serial() {
      if (this.hasOneDevice) {
        return (
          this.$store.getters['devices/getSerialByDeviceId'](
            this.deviceIds[0]
          ) || ''
        )
      }
      return ''
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen
      this.$emit('fullscreenChange', this.isFullscreen)
    },
    removeDevice(index, options) {
      this.$emit('removeDevice', index, options)
    },
    removeWidget() {
      this.$emit('removeWidget')
    },
    async updateWidget(event) {
      const { widgetId, deviceIds, previousDeviceIds } = event
      const config = { deviceIds }
      // Settings need to be reset to default when widget deviceIds change
      // and widget contains a single device that was not previously included
      if (deviceIds.length === 1) {
        const isNewDevice = !previousDeviceIds.includes(deviceIds[0])
        if (isNewDevice) {
          config['settings'] = {
            center: {
              lat: 0,
              lng: 0,
            },
            zoom: 0,
            selectedParameter: 0,
          }
        }
      }
      const resp = await this.$api.patchWidget(config, widgetId)
      if (resp.ok || resp.status === HTTPStatus.NotFound) {
        this.$emit('refreshDashboard')
      } else {
        if (this.hasNotifications) {
          const message = {
            text: this.$t('dashboard.messages.errorUpdateWidget'),
            type: messageTypes.ERROR,
            timeout: SNACKBAR_STATIC_DURATION_MS,
          }
          this.$store.dispatch('notifications/addMessage', message)
        }
      }
    },
    async setIsNameOverflowing() {
      await Vue.nextTick()
      this.isNameOverflowing = isChildOverflowing(this.$refs.deviceName)
    },
    allowDeviceWidget(deviceId) {
      const isShared =
        this.$store.getters['devices/getIsSharedByDeviceId'](deviceId)
      const isOwned =
        this.$store.getters['devices/getAccountByDeviceId'](deviceId) ===
        this.accountId
      return isShared || isOwned
    },
    checkDeviceAccess() {
      if (this.allDevices.length > 0) {
        this.deviceIds?.forEach((id, index) => {
          if (!this.allDevices.includes(id)) {
            this.removeDevice(index, { force: true })
          }
        })
      }
    },
    observe(el) {
      const setDims = throttle((entries) => {
        const cr = entries[0].contentRect
        const dims = {
          width: cr.width,
          height: cr.height,
        }
        this.$emit('widgetSizeChanged', dims)
      }, 200)

      this.observer = new ResizeObserver(setDims)
      if (el instanceof Element) {
        this.observer.observe(el)
      }
    },
  },
  async mounted() {
    if (this.observeSize) this.observe(this.$el)
    this.accountId = await getUserAccountId(this.$auth)
    this.checkDeviceAccess()
  },
  updated() {
    this.setIsNameOverflowing()
  },
  destroyed() {
    if (this.observer) this.observer.disconnect()
  },
}
</script>

<style lang="scss">
.widget-fullscreen-button.v-btn:not(.v-btn--round).v-size--default {
  min-width: 36px;
  padding: 0;
}

.small-subtitle {
  padding: 4px 6px;
  white-space: nowrap;

  .value {
    color: black;
    font-weight: 500;
  }

  span {
    font-size: 0.7rem;

    @media (max-width: 480px) {
      font-size: 0.675rem;
    }
  }
}
</style>
<style scoped lang="scss">
.widget {
  display: flex;
  flex-direction: column;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.widget-fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  // Find a better animation than this once we have size as a prop
  // transition: all 0.2s linear;
}

.widget-header-container,
.widget-footer-container {
  flex-wrap: nowrap;
  max-width: 100%;
  min-height: 42px;
  padding: 0 4px;
  font-size: 15px;
}

.widget-header-container {
  .widget-actions {
    margin-left: 0px;
    margin-right: 8px;
  }
  .widget-title-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: 4px;
    .widget-title {
      font-size: 16px;
      margin-right: 4px;
      margin-left: 4px;
      height: 22px;
      line-height: 26px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .widget-subtitle {
      margin-left: 4px;
      height: 12px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .widget-left-toolbar,
  .widget-right-toolbar {
    white-space: nowrap;
  }

  .fullscreen-button {
    padding: 0;
  }
}

.widget-footer-container {
  padding: 6px 12px;
}

.v-card__title {
  .v-btn {
    padding: 0;
    min-width: 10px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;

    .v-icon {
      font-size: 22px;
    }
  }
}
.widget-content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 12px;
}

.divider {
  opacity: 0.36;
}

.widget-disconnected-device-theme {
  border: 1px solid red;

  .widget-header-container {
    background-color: red;
    color: white !important;

    button,
    span,
    ::v-deep .v-icon {
      color: white !important;
    }
  }
}
</style>
