<template>
  <div class="study-footer" v-if="!isLoading">
    <component
      v-for="field in calibrationFields"
      :key="field.dataCy"
      :is="field.componentName"
      :value="field.value"
      :label="field.label"
      :invalidText="field.invalidText"
      customClass="small-subtitle"
      :dataCy="field.dataCy"
    />
  </div>
</template>

<script>
import popoverTimestamp from '@/components/devicePopover/content/popoverTimestamp.vue'
import popoverNumber from '@/components/devicePopover/content/popoverNumber.vue'
import popoverText from '@/components/devicePopover/content/popoverText.vue'
import {
  getCalibrationDateFromStudyStates,
  getFlowCalibrationFactorFromStudyStates,
  getPhotometricCalFactorFromStudyStates,
  getSizeCalFactorFromStudyStates,
} from '@/helpers/studies/calibrationHelper'
import { getLatestStudyTelemetry } from '@/helpers/studies/telemetryHelper'
import { activeLastDay } from '@/services/device-status'

export default {
  name: 'ContinuousMonitoringAndStudyFooter',
  components: {
    'popover-timestamp': popoverTimestamp,
    'popover-number': popoverNumber,
    'popover-text': popoverText,
  },
  props: {
    isLoading: Boolean,
    latestDeviceStudy: Object,
  },
  computed: {
    latestStudyTelemetry() {
      return getLatestStudyTelemetry(this.latestDeviceStudy)
    },
    latestStudyTelemetryTimestamp() {
      return this.latestStudyTelemetry?.timestamp
    },
    isTelemetryActive() {
      return activeLastDay(this.latestStudyTelemetryTimestamp)
    },
    studyStates() {
      return this.latestDeviceStudy?.states
        ? this.latestDeviceStudy.states
        : null
    },
    calibrationDate() {
      return getCalibrationDateFromStudyStates(this.studyStates)
    },
    flowCalibrationFactor() {
      return getFlowCalibrationFactorFromStudyStates(this.studyStates)
    },
    photometricCalFactor() {
      return getPhotometricCalFactorFromStudyStates(this.studyStates)
    },
    sizeCalFactor() {
      return getSizeCalFactorFromStudyStates(this.studyStates)
    },
    calibrationFields() {
      return [
        {
          componentName: 'popover-timestamp',
          value: this.getFormattedValue(this.calibrationDate),
          label: this.$t('widget.currentReadings.lastCalibrationDate'),
          invalidText: this.$t('map.popover.valueBlank'),
          dataCy: 'lastCalibrationDate',
        },
        {
          componentName: 'popover-number',
          value: this.getFormattedValue(this.photometricCalFactor),
          label: this.$t('widget.currentReadings.photometricCalFactor'),
          dataCy: 'photometricCalFactor',
        },
        {
          componentName: 'popover-text',
          label: this.$t('widget.currentReadings.calNumber'),
          invalidText: this.$t('map.popover.valueBlank'),
          dataCy: 'calNumber',
        },
        {
          componentName: 'popover-number',
          value: this.getFormattedValue(this.sizeCalFactor),
          label: this.$t('widget.currentReadings.sizeCalFactor'),
          dataCy: 'sizeCalFactor',
        },
        {
          componentName: 'popover-text',
          label: this.$t('widget.currentReadings.calName'),
          invalidText: this.$t('map.popover.valueBlank'),
          dataCy: 'calName',
        },
        {
          componentName: 'popover-number',
          value: this.getFormattedValue(this.flowCalibrationFactor),
          label: this.$t('widget.currentReadings.flowCalibrationFactor'),
          dataCy: 'flowCalibrationFactor',
        },
      ]
    },
  },
  methods: {
    getFormattedValue(value) {
      return this.isTelemetryActive ? value : null
    },
  },
}
</script>

<style lang="scss" scoped>
.study-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .small-subtitle {
    padding: 0;
    line-height: 1.2;
    min-width: 35%;
  }

  .small-subtitle:nth-child(even) {
    text-align: end;
  }
}
</style>
