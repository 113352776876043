var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("popover-text", {
    attrs: {
      value: _vm.formattedValue,
      label: _vm.label,
      customClass: _vm.customClass,
      dataCy: _vm.dataCy,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }